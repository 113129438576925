/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Role {
  name: string;
  description?: string;
  ruleName?: string;
  setting: RoleSetting;
  createdAt?: string;
  updatedAt?: string;
}

export interface Permission {
  name: string;
  description?: string;
  ruleName?: string;
  refer?: string;
  collection?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum PermissionReferType {
  ROUTE = 'Route',
  FIELD = 'Field',
}

export type RoleSetting = {
  id?: string;
  displayName?: string;
  theme?: string;
}

export interface IBaseRbacItemResponse {
  name: string;
  description: string;
  ruleName: string;
  createdAt: string;
  updatedAt: string;
}

export interface IPermissionResponse extends IBaseRbacItemResponse {}

export interface IRoleResponse extends IBaseRbacItemResponse {}

export interface IBaseRbacItemRequest {
  name: string;
  description?: string;
  ruleName?: string;
}

export interface ICreatePermissionRequest extends IBaseRbacItemRequest{}

export interface IUpdatePermissionRequest extends IBaseRbacItemRequest{}

export interface ICreateRoleRequest extends IBaseRbacItemRequest{}

export interface IUpdateRoleRequest extends IBaseRbacItemRequest{}

export enum RolesEnum {
  ROLE_ADMIN_SYSTEM = 'ROLE_ADMIN_SYSTEM',
  ROLE_ADMIN_COMPANY = 'ROLE_ADMIN_COMPANY',
  ROLE_USER = 'ROLE_USER',
}

export enum PermissionsEnum {
  USER_LIST = 'USER_LIST',
  USER_EDIT = 'USER_EDIT',
  USER_CREATE = 'USER_CREATE',
  ADMIN_DASHBOARD_VIEW = 'ADMIN_DASHBOARD_VIEW',
  ORG_PAYMENT_VIEW = 'ORG_PAYMENT_VIEW',
  ORG_PAYMENT_ADD_EDIT = 'ORG_PAYMENT_ADD_EDIT',
  ORG_INCOMES_VIEW = 'ORG_INCOMES_VIEW',
  ORG_INCOMES_EDIT = 'ORG_INCOMES_EDIT',
  ORG_EXPENSES_VIEW = 'ORG_EXPENSES_VIEW',
  ORG_EXPENSES_EDIT = 'ORG_EXPENSES_EDIT',
  PROFILE_EDIT = 'PROFILE_EDIT',
  EMPLOYEE_EXPENSES_VIEW = 'EMPLOYEE_EXPENSES_VIEW',
  EMPLOYEE_EXPENSES_EDIT = 'EMPLOYEE_EXPENSES_EDIT',
  ORG_PROPOSALS_VIEW = 'ORG_PROPOSALS_VIEW',
  ORG_PROPOSALS_EDIT = 'ORG_PROPOSALS_EDIT',
  ORG_PROPOSAL_TEMPLATES_VIEW = 'ORG_PROPOSAL_TEMPLATES_VIEW',
  ORG_PROPOSAL_TEMPLATES_EDIT = 'ORG_PROPOSAL_TEMPLATES_EDIT',
  ORG_TIME_OFF_VIEW = 'ORG_TIME_OFF_VIEW',
  ORG_EMPLOYEES_VIEW = 'ORG_EMPLOYEES_VIEW',
  ORG_EMPLOYEES_EDIT = 'ORG_EMPLOYEES_EDIT',
  ORG_CANDIDATES_VIEW = 'ORG_CANDIDATES_VIEW',
  ORG_CANDIDATES_EDIT = 'ORG_CANDIDATES_EDIT',
  ORG_CANDIDATES_INTERVIEW_EDIT = 'ORG_CANDIDATES_INTERVIEW_EDIT',
  ORG_CANDIDATES_DOCUMENTS_VIEW = 'ORG_CANDIDATES_DOCUMENTS_VIEW',
  ORG_CANDIDATES_TASK_EDIT = 'ORG_CANDIDATES_TASK_EDIT',
  ORG_CANDIDATES_FEEDBACK_EDIT = 'ORG_CANDIDATES_FEEDBACK_EDIT',
  ORG_INVENTORY_PRODUCT_EDIT = 'ORG_INVENTORY_PRODUCT_EDIT',
  ORG_INVENTORY_VIEW = 'ORG_INVENTORY_VIEW',
  ORG_TAGS_EDIT = 'ORG_TAGS_EDIT',
  ORG_USERS_VIEW = 'ORG_USERS_VIEW',
  ORG_USERS_EDIT = 'ORG_USERS_EDIT',
  ORG_INVITE_VIEW = 'ORG_INVITE_VIEW',
  ORG_INVITE_EDIT = 'ORG_INVITE_EDIT',
  ALL_ORG_VIEW = 'ALL_ORG_VIEW',
  ALL_ORG_EDIT = 'ALL_ORG_EDIT',
  POLICY_VIEW = 'POLICY_VIEW',
  POLICY_EDIT = 'POLICY_EDIT',
  TIME_OFF_EDIT = 'TIME_OFF_EDIT',
  REQUEST_APPROVAL_VIEW = 'REQUEST_APPROVAL_VIEW',
  REQUEST_APPROVAL_EDIT = 'REQUEST_APPROVAL_EDIT',
  APPROVAL_POLICY_VIEW = 'APPROVALS_POLICY_VIEW',
  APPROVAL_POLICY_EDIT = 'APPROVALS_POLICY_EDIT',
  CHANGE_SELECTED_EMPLOYEE = 'CHANGE_SELECTED_EMPLOYEE',
  CHANGE_SELECTED_CANDIDATE = 'CHANGE_SELECTED_CANDIDATE',
  CHANGE_SELECTED_ORGANIZATION = 'CHANGE_SELECTED_ORGANIZATION',
  CHANGE_ROLES_PERMISSIONS = 'CHANGE_ROLES_PERMISSIONS',
  ACCESS_PRIVATE_PROJECTS = 'ACCESS_PRIVATE_PROJECTS',
  TIMESHEET_EDIT_TIME = 'TIMESHEET_EDIT_TIME',
  SUPER_ADMIN_EDIT = 'SUPER_ADMIN_EDIT',
  PUBLIC_PAGE_EDIT = 'PUBLIC_PAGE_EDIT',
  INVOICES_VIEW = 'INVOICES_VIEW',
  INVOICES_EDIT = 'INVOICES_EDIT',
  ESTIMATES_VIEW = 'ESTIMATES_VIEW',
  ESTIMATES_EDIT = 'ESTIMATES_EDIT',
  ORG_CANDIDATES_INTERVIEWERS_EDIT = 'ORG_CANDIDATES_INTERVIEWERS_EDIT',
  ORG_CANDIDATES_INTERVIEWERS_VIEW = 'ORG_CANDIDATES_INTERVIEWERS_VIEW',
  VIEW_ALL_EMAILS = 'VIEW_ALL_EMAILS',
  VIEW_ALL_EMAIL_TEMPLATES = 'VIEW_ALL_EMAIL_TEMPLATES',
  ORG_HELP_CENTER_EDIT = 'ORG_HELP_CENTER_EDIT',
  VIEW_SALES_PIPELINES = 'VIEW_SALES_PIPELINES',
  EDIT_SALES_PIPELINES = 'EDIT_SALES_PIPELINES',
  CAN_APPROVE_TIMESHEET = 'CAN_APPROVE_TIMESHEET',
  ORG_SPRINT_VIEW = 'ORG_SPRINT_VIEW',
  ORG_SPRINT_EDIT = 'ORG_SPRINT_EDIT',
  ORG_PROJECT_EDIT = 'ORG_PROJECT_EDIT',
  ORG_CONTACT_EDIT = 'ORG_CONTACT_EDIT',
  ORG_CONTACT_VIEW = 'ORG_CONTACT_VIEW',
  ORG_TEAM_EDIT = 'ORG_TEAM_EDIT',
  ORG_CONTRACT_EDIT = 'ORG_CONTRACT_EDIT',
  EVENT_TYPES_VIEW = 'EVENT_TYPES_VIEW',
  TIME_TRACKER = 'TIME_TRACKER',
  TENANT_ADD_EXISTING_USER = 'TENANT_ADD_EXISTING_USER',
  INTEGRATION_VIEW = 'INTEGRATION_VIEW',
  FILE_STORAGE_VIEW = 'FILE_STORAGE_VIEW',
  PAYMENT_GATEWAY_VIEW = 'PAYMENT_GATEWAY_VIEW',
  SMS_GATEWAY_VIEW = 'SMS_GATEWAY_VIEW',
  CUSTOM_SMTP_VIEW = 'CUSTOM_SMTP_VIEW',
  IMPORT_EXPORT_VIEW = 'IMPORT_EXPORT_VIEW',
  MIGRATE_GAUZY_CLOUD = "MIGRATE_GAUZY_CLOUD",
  ORG_JOB_EMPLOYEE_VIEW = 'ORG_JOB_EMPLOYEE_VIEW',
  ORG_JOB_MATCHING_VIEW = 'ORG_JOB_MATCHING_VIEW',
  INVENTORY_GALLERY_VIEW = 'INVENTORY_GALLERY_VIEW',
  INVENTORY_GALLERY_EDIT = 'INVENTORY_GALLERY_EDIT',
  ORG_EQUIPMENT_VIEW = 'ORG_EQUIPMENT_VIEW',
  ORG_EQUIPMENT_EDIT = 'ORG_EQUIPMENT_EDIT',
  ORG_EQUIPMENT_SHARING_VIEW = 'ORG_EQUIPMENT_SHARING_VIEW',
  ORG_EQUIPMENT_SHARING_EDIT = 'ORG_EQUIPMENT_SHARING_EDIT',
  EQUIPMENT_MAKE_REQUEST = 'EQUIPMENT_MAKE_REQUEST',
  EQUIPMENT_APPROVE_REQUEST = 'EQUIPMENT_APPROVE_REQUEST',
  ORG_PRODUCT_TYPES_VIEW = 'ORG_PRODUCT_TYPES_VIEW',
  ORG_PRODUCT_TYPES_EDIT = 'ORG_PRODUCT_TYPES_EDIT',
  ORG_PRODUCT_CATEGORIES_VIEW = 'ORG_PRODUCT_CATEGORIES_VIEW',
  ORG_PRODUCT_CATEGORIES_EDIT = 'ORG_PRODUCT_CATEGORIES_EDIT',
  VIEW_ALL_ACCOUNTING_TEMPLATES = 'VIEW_ALL_ACCOUNTING_TEMPLATES',
  ACCESS_DELETE_ACCOUNT = "ACCESS_DELETE_ACCOUNT",
    ACCESS_DELETE_ALL_DATA = "ACCESS_DELETE_ALL_DATA",
  TENANT_SETTING = "TENANT_SETTING", //allow user to access tenant setting
  ALLOW_DELETE_TIME = "ALLOW_DELETE_TIME",
  ALLOW_MODIFY_TIME = "ALLOW_MODIFY_TIME",
  ALLOW_MANUAL_TIME = "ALLOW_MANUAL_TIME",
  DELETE_SCREENSHOTS = "DELETE_SCREENSHOTS",
}